/* NOTE: Only import with ssr: false; */
import { List, ListItem } from '@chakra-ui/react';
import {
  FacebookLogo,
  InstagramLogo,
  LinkedinLogo,
  TwitterLogo,
  WhatsappLogo,
  YoutubeLogo,
} from '@phosphor-icons/react';
import Link from './Link';

interface SocialShareProps {
  title: string;
}

export const renderIcon = (service: string) => {
  switch (service) {
    case 'Facebook':
      return <FacebookLogo size={34} />;
    case 'Instagram':
      return <InstagramLogo size={34} />;
    case 'Youtube':
      return <YoutubeLogo size={34} />;
    case 'Twitter':
      return <TwitterLogo size={34} />;
    case 'LinkedIn':
      return <LinkedinLogo size={34} />;
    case 'Whatsapp':
      return <WhatsappLogo size={34} />;
    default:
      return null;
  }
};

const SocialShare = ({ title }: SocialShareProps) => {
  // NOTE: renderIcon might still be imported from here without ssr: false flag, which is why the window check has stay.
  if (
    typeof window === 'undefined' ||
    typeof window?.location?.href !== 'string'
  ) {
    return null;
  }

  const shareLinks = [
    {
      service: 'Twitter',
      link: `https://twitter.com/intent/tweet?text=${title}&url=${window.location.href}`,
    },
    {
      service: 'Facebook',
      link: `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
    },
    {
      service: 'LinkedIn',
      link: `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`,
    },
    {
      service: 'Whatsapp',
      link: `whatsapp://send?text=${window.location.href}`,
    },
  ];

  return (
    <List display="flex" gridGap={6} py={5}>
      {shareLinks?.map(({ link, service }) => (
        <ListItem color="success.700" key={service}>
          <Link href={link} display="flex" alignItems="center" title={service}>
            {renderIcon(service)}
          </Link>
        </ListItem>
      ))}
    </List>
  );
};
export default SocialShare;
