import { useCallback, useContext } from 'react';
import type { Overlap } from '@/components/frontend/ArticleLayout';
import { AppContext, LayoutContext } from '../context';

export const useAppdata = () => {
  const [appData] = useContext(AppContext);
  return appData;
};

type Template = 'front-page' | 'campaign-listings-page';

export const useIsTemplate = (matches: Template) => {
  const [{ template }] = useContext(AppContext);

  return template === matches;
};

export const useLayout = () => {
  const [state, setState] = useContext(LayoutContext);
  const setOverlap = useCallback(
    (newOverlap: null | Overlap) => {
      setState((s) => ({
        ...s,
        overlap: newOverlap,
      }));
    },
    [setState],
  );

  return {
    ...state,
    setOverlap,
  };
};
