import { NavPost } from '@/types';

type FlattenChildren = (Omit<NavPost, 'children'> & { children: null })[];

const flattenChildren = (children: NavPost[]): FlattenChildren =>
  children.reduce<FlattenChildren>((acc, child) => {
    if (!child.children) {
      return [...acc, child] as FlattenChildren; // TS just cant without
    }
    const { children: grandChildren, ...childItem } = child;
    return [
      ...acc,
      { ...childItem, children: null },
      ...flattenChildren(grandChildren),
    ];
  }, []);

// WP can build navigations of any depth, but our app only supports up to 2 levels.
export const resolveMainNavItems = (items: NavPost[]) =>
  items.map((item) => {
    if (!item.children) {
      return item;
    }
    if (!item.children.some(({ children: grandChildren }) => grandChildren)) {
      return item;
    }
    const { children, ...navItem } = item;
    return {
      ...navItem,
      children: flattenChildren(children),
    };
  });

export default { resolveMainNavItems };
