import { ComponentStyleConfig } from '@chakra-ui/react';

const Tabs: ComponentStyleConfig = {
  baseStyle: {
    tablist: {
      '@media print': { display: 'none' }, // print has no js functionality
    },
  },
  variants: {
    line: {
      tablist: {
        border: 0,
        mb: 4,
      },
      tab: {
        px: 0,
        fontWeight: 600,
        _selected: {
          color: 'highlightColor',
        },
        _notLast: {
          mr: 5,
        },
      },
    },
  },
  defaultProps: {
    colorScheme: 'green',
  },
};

export default Tabs;
