export default {
  global: {
    'html, body': {
      // background: 'gray.100',
    },
    body: {
      overflowX: 'hidden',
      '&.mobile-menu-open': {
        overflow: 'hidden',

        '#__next': {
          // Override whatever feedback things we have when mobile nav is open
          zIndex: 200,
          pos: 'relative',
        },
        '.ld-chat-launcher': {
          // this one sets 2131232132133425 !important by default like you of course should
          zIndex: '100 !important',
        },
      },
      'div[class^="_hj-"][class$="__Feedback__container"]': {
        // Hotjargon or whatever. By default it sets some 123134213213 value for z-index
        zIndex: 100,
      },
      '@media print': {
        margin: '.5cm',
        '& > *:not(#__next)': {
          display: 'none !important',
        },
        '*': {
          textShadow: 'none!important',
          boxShadow: 'none!important',
        },
        '*:not(.print-background)': {
          background: 'transparent!important',
        },
        'blockquote, pre, img,tr,h2,h3, p, ul, ol, .print-block, .maplibregl-map':
          {
            pageBreakInside: 'avoid',
          },
        'ul, ol': {
          pageBreakAfter: 'avoid',
        },
        'h2,h3': {
          pageBreakAfter: 'avoid',
        },
        'h2,h3,p': {
          orphans: 3,
          widows: 3,
        },
        'blockquote, pre': {
          border: '1px solid grey',
        },
        'a,a:visited': {
          textDecoration: 'underline',
        },
        'a[href^="/"]:not([href*="#"]):after, a[href^="http"]:after': {
          content: '" (" attr(href) ")"',
        },
        img: {
          maxWidth: '100% !important',
        },
      },
    },
  },
};
