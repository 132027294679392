const focusHover = {
  transitionTimingFunction: 'ease-out',
  outline: 'none',
  boxShadow: 'none !important',
  borderColor: 'currentColor',
};

export const baseInputStyle = {
  borderRadius: '4px',
  fontWeight: '500',
  border: '1.5px solid',
  borderColor: 'inputBorderColor',
  transition: 'color .1s ease-in, border-color .1s ease-in',
  _placeholder: {
    color: '#807D72',
  },
  _invalid: {
    ':not(:focus)': {
      borderColor: 'error.500',
    },
    transitionTimingFunction: 'ease-out',
  },
  _focus: { ...focusHover },
  _hover: {
    ...focusHover,
    transitionDelay: '.25s',
    transitionDuration: '6.5s',
    _focus: { transition: 'none' },
  },
};

const Input = {
  baseStyle: {
    field: baseInputStyle,
  },
  sizes: {
    md: {},
  },
  variants: {
    outline: {
      field: {
        ...baseInputStyle,
        bg: 'white',
        borderWidth: '1px',
      },
    },
  },
  defaultProps: {
    focusBorderColor: 'currentColor !important',
    hoverBorderColor: 'currentColor',
  },
};

export default Input;
