import { Alert, AlertIcon, Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export default function MaintenanceBreakBanner() {
  const { t } = useTranslation('frontoffice');
  if (process.env.NEXT_PUBLIC_MAINTENANCE_BREAK !== 'true') {
    return null;
  }

  return (
    <Box>
      <Alert status="info" justifyContent="center" display="flex">
        <AlertIcon />
        {t(
          'maintenance_break_banner',
          'Teemme huoltotöitä. Jotkut toiminnot eivät ole käytettävissä. Ongelmatilanteissa ota yhteyttä välittäjään.',
        )}
      </Alert>
    </Box>
  );
}
