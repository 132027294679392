import {
  Container,
  Flex,
  SimpleGrid,
  Box,
  Text,
  List,
  ListItem,
  VStack,
} from '@chakra-ui/react';
import { Trans, useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useAppdata } from '@/lib/hooks/useApp';
import Logo from '@/components/common/Logo';
import SocialMediaLinkList from '@/components/frontend/SocialMediaLinkList';
import Link from '@/components/frontend/Link';
import { NavPost } from '@/types';
import { urls } from '@/lib/urls';
import { resolveMainNavItems } from '@/lib/utils/navigations';

const year = new Date().getFullYear();
const nbsp = '\xa0';

const makeFooterNav = (nav: NavPost[]): NavPost[] =>
  resolveMainNavItems(nav || []).map((item) => {
    if (item.url === '/osta') {
      return {
        ...item,
        children: item.children ? [...item.children] : [], // TODO: remove or populate with propertyTypes? Or should be in WP?
      };
    }
    return item;
  });

const Footer = () => {
  const { t } = useTranslation('frontoffice');
  const {
    navigations: {
      main_navigation,
      legal_navigation: footerLinks,
      social_media_links: socialMediaLinks,
    },
  } = useAppdata();
  const { locale } = useRouter();
  const nav = makeFooterNav(main_navigation);

  return (
    <Flex backgroundColor="primary.600" width="100%">
      <Container
        maxWidth="calc(var(--chakra-sizes-container-2xl) - 100px + 2rem)"
        color="white"
      >
        <Logo logoType="white-alt" py={10} locale={locale} />
        <SimpleGrid columns={[1, 2, 3, 6]} spacing={5} color="white" as={List}>
          {nav.map(({ title, children, url }, i) => (
            <ListItem key={`footer-item-${url || title || i}`}>
              <Link href={url} fontWeight="600" mb="1" display="inline-block">
                {title}
              </Link>
              {children ? (
                <List key={`list-${url || i}`}>
                  {children.map(({ title: child, url: childUrl }) => (
                    <ListItem key={childUrl} mb="1">
                      <Link href={childUrl}>{child}</Link>
                    </ListItem>
                  ))}
                </List>
              ) : null}
            </ListItem>
          ))}
          {socialMediaLinks?.length ? (
            <Box mb={5}>
              <VStack spacing={2} alignItems="flex-start">
                <Text fontWeight="600">
                  {t('follow_us_on', 'Seuraa meitä')}
                </Text>
                <SocialMediaLinkList socialMediaLinks={socialMediaLinks} />
              </VStack>
            </Box>
          ) : null}
        </SimpleGrid>
        <Flex py={10} alignItems="baseline" fontSize="sm" flexWrap="wrap">
          <Text mr="4" mb="2">
            ©{nbsp}
            {year}
            {nbsp}
            {t('service_name', 'Metsätilat.fi')}
          </Text>
          <Flex as={List} mb="2">
            {footerLinks?.map(({ title, url }) => (
              <ListItem key={url} mb="1" _notLast={{ mr: 4 }}>
                <Link href={url}>{title}</Link>
              </ListItem>
            ))}
            <ListItem key="backoffice" mb="1" alignSelf="flex-end">
              {/* use absolute url to force www. fixes suspected DNS issue on some users */}
              <Link href={urls.backOffice.root}>
                {t('for_realtors', 'Välittäjille')}
              </Link>
            </ListItem>
          </Flex>
          <Box flexBasis="100%" fontSize="xs">
            <Trans t={t} i18nKey="footer_recaptcha_info">
              This site is protected by reCAPTCHA and the Google{' '}
              <Link href="https://policies.google.com/privacy">
                Privacy Policy
              </Link>{' '}
              and{' '}
              <Link href="https://policies.google.com/terms">
                Terms of Service
              </Link>{' '}
              apply.
            </Trans>
          </Box>
        </Flex>
      </Container>
    </Flex>
  );
};

export default Footer;
