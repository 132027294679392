export default {
  secondaryColor: '#4A5568', // gray 600
  mutedColor: '#807D72',
  backgroundColor: '#f9f8f5',
  highlightColor: '#007744',
  highlightBackground: '#dcefdf',
  inputBorderColor: '#D8D6CD',
  inputBorderColorLight: '#F4F3EF',
  green: {
    1: '#5BB250',
    2: '#007744',
    3: '#81AA73',
    4: '#374632',
    5: '#193219',
    6: '#DCEFDF',
  },
  red: {
    1: '#EB6039',
    2: '#963719',
    3: '#C47B48',
    4: '#6E4632',
    5: '#371400',
    6: '#FBDFD7',
  },
  yellow: {
    1: '#EAB425',
    2: '#90740D',
    3: '#C0B443',
    4: '#6E6432',
    5: '#372E00',
    6: '#FBF0D3',
  },
  gray: {
    1: '#F9F8F6',
    2: '#F4F3EF',
    3: '#EAE8E0',
    4: '#D8D6CD',
    5: '#807D72',
  },
  primary: {
    50: '#d6ffef',
    100: '#c9f7e5',
    200: '#bcefdb',
    300: '#a1dec7',
    400: '#6bbd9e',
    500: '#007b4c',
    600: '#00482d',
    700: '#002e1d',
    800: '#002115',
    900: '#00140d',
  },
  success: {
    50: '#dcefdf',
    100: '#cce8ce',
    200: '#bce0bc',
    300: '#9cd198',
    400: '#7cc274',
    500: '#5bb250',
    600: '#529749',
    700: '#007b4c',
    800: '#40613a',
    900: '#374632',
  },
  warning: {
    50: '#faedcb',
    100: '#f6dfa2',
    200: '#f2d178',
    300: '#f0ca64',
    400: '#eec34f',
    500: '#eab425',
    600: '#be931c',
    700: '#917113',
    800: '#64500a',
    900: '#372e00',
  },
  error: {
    50: '#fbdfd7',
    100: '#f7c0b0',
    200: '#f3a088',
    300: '#f19075',
    400: '#ef8061',
    500: '#eb6039',
    600: '#913a1d',
    700: '#64270f',
    800: '#4e1e08',
    900: '#371400',
  },
};
