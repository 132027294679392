import { ComponentStyleConfig } from '@chakra-ui/react';

const Heading: ComponentStyleConfig = {
  baseStyle: {
    wordBreak: 'break-word',
    fontWeight: 600,
  },
  variants: {
    subheading: {
      fontSize: '1.25rem',
      fontWeight: 400,
    },
    subtitle: {
      fontSize: '1.25rem',
      fontWeight: 600,
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'none',
  },
};

export default Heading;
