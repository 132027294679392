import { ComponentStyleConfig } from '@chakra-ui/react';

const Accordion: ComponentStyleConfig = {
  baseStyle: {
    container: {
      border: 0,
      '@media print': {
        '.chakra-collapse': {
          display: 'block !important',
          height: 'auto !important',
          opacity: '1 !important',
          mb: '1cm',
        },
      },
    },
    button: {
      outline: 'none',
      _focus: {
        outline: 'none !important',
        boxShadow: 'none',
      },
      font: 'inherit',
    },
  },
  variants: {
    nav: {
      button: {
        pos: 'relative',
        px: 4,
        py: 2,
        justifyContent: 'space-between',
        '.chakra-collapse .chakra-collapse &': {
          pr: 0,
        },
      },
    },
    plain: {
      button: {
        p: 0,
        _hover: { background: 'transparent' },
      },
      item: {
        border: 'none',
        p: 0,
        outline: 'none',
      },
      panel: {
        pb: 0,
      },
    },
    content: {
      button: {
        fontSize: 'inherit',
        outline: 'none',
        _hover: {
          bg: 'inherit',
        },
        svg: {
          color: 'primary.500',
        },
      },
    },
  },
};

export default Accordion;
