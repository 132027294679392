export default {
  noPrint: {
    '@media print': { display: 'none !important' },
  },
  fullWidth: {
    position: 'relative',
    gridColumn: '-1 / 1 !important', // using important here is not necessary, but convinience mostly and there's no point for it to not be final.
  },
  grid: {
    gridTemplateColumns: [
      'repeat(auto-fit, minmax(280px, 500px))',
      null,
      'repeat(auto-fill, minmax(280px, 1fr))',
    ],
    alignItems: 'flex-start',
    gridRowGap: [4, 6],
    gridColumnGap: 6,
  },
  smGrid: {
    gridTemplateColumns: [
      'repeat(auto-fit, minmax(185px, 500px))',
      null,
      'repeat(auto-fit, minmax(185px, 1fr))',
    ],
    alignItems: 'flex-start',
    gridRowGap: [4, 6],
    gridColumnGap: 6,
    '> *:only-child': { maxWidth: '500px' },
  },
  lgGrid: {
    gridTemplateColumns: [
      'repeat(auto-fit, minmax(285px, 500px))',
      null,
      'repeat(auto-fill, minmax(400px, 1fr))',
    ],
    alignItems: 'flex-start',
    gridRowGap: [4, 6],
    gridColumnGap: 6,
  },
};
