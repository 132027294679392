import { mode, transparentize } from '@chakra-ui/theme-tools';

const variantGhost = (props: Record<string, unknown> & { theme: any }) => {
  const { colorScheme: c, theme } = props;

  if (c === 'gray') {
    return {
      color: mode(`inherit`, `whiteAlpha.900`)(props),
      _hover: {
        bg: mode(`gray.100`, `whiteAlpha.200`)(props),
      },
      _active: { bg: mode(`gray.200`, `whiteAlpha.300`)(props) },
    };
  }

  const darkHoverBg = transparentize(`${c}.200`, 0.12)(theme);
  const darkActiveBg = transparentize(`${c}.200`, 0.24)(theme);

  return {
    color: mode(`${c}.700`, `${c}.200`)(props),
    bg: `${c}.50`,
    _hover: {
      bg: mode(`${c}.100`, darkHoverBg)(props),
    },
    _active: {
      bg: mode(`${c}.100`, darkActiveBg)(props),
    },
  };
};

export default {
  variants: {
    outline: {
      borderWidth: '1px',
      // _hover: {
      //   bg: 'primary.500',
      //   color: 'white',
      //   borderWidth: '1px',
      //   borderColor: 'primary.500'
      // },
      // _active: {
      //   bg: 'primary.900',
      //   color: 'white'
      // }
    },
    ghost: variantGhost,
    alt: {
      background: 'success.50',
      color: 'black',
      svg: {
        color: 'highlightColor',
      },
    },
    altToggle: {
      // default gray button, hover and active have
      // light green color
      fontWeight: 500,
      background: 'inputBorderColorLight',
      color: 'mutedColor',
      svg: {
        color: 'mutedColor',
      },
      _hover: {
        background: 'success.50',
        color: 'black',
        svg: {
          color: 'highlightColor',
        },
      },
      _active: {
        background: 'success.50',
        color: 'black',
        svg: {
          color: 'highlightColor',
        },
      },
      _disabled: {
        background: 'inputBorderColorLight',
        color: 'mutedColor',
        svg: {
          color: 'mutedColor',
        },
      },
    },
    sliderArrow: {
      borderRadius: '50%',
      bg: 'success.50',
      borderColor: 'highlightColorBackground',
      width: '42px',
      height: '42px',
      color: 'highlightColor',
      padding: '0',
      top: '50%',
    },
  },
  sizes: {
    md: {
      borderRadius: '200px',
      fontSize: '.875rem',
      px: '1.5rem',
    },
    lg: {
      borderRadius: '200px',
      fontSize: '.875rem',
      px: '1.5rem',
    },
  },
};
