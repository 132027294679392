import { forwardRef } from 'react';
import { Box, BoxProps, useBreakpointValue } from '@chakra-ui/react';
import { getNextBreakpoint, breakpoints } from '@/theme';

export const mobileNavBreakpointKey = 'md';

export const mobileNavMaxBreakpointValue = `${
  Number.parseInt(
    breakpoints[mobileNavBreakpointKey as keyof typeof breakpoints],
    10,
  ) - 0.001
}em`;

export const useIsMobileHeader = () => {
  const isNotMobile = useBreakpointValue({ [mobileNavBreakpointKey]: true });
  return !isNotMobile;
};

export const navNextBreakpointKey = getNextBreakpoint(mobileNavBreakpointKey);

export const arrayShift = <T extends unknown>([, ...shifted]: T[]): T[] =>
  shifted;

export const NavList = forwardRef<HTMLDivElement, BoxProps>((props, ref) => (
  <Box as="ul" listStyleType="none" ref={ref} {...props} />
));
export const NavListItem = (props: BoxProps) => (
  <Box as="li" listStyleType="none" {...props} />
);
