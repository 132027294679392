import { TFunction } from 'next-i18next';
import { ReactNode } from 'react';

const sanitizeCmsStrings = (s: string): string => {
  if (s.indexOf('&nbsp;') > -1) {
    return s.replace(/&nbsp;/g, ' ').trimRight();
  }
  const re = new RegExp(String.fromCharCode(160), 'g');

  if (re.test(s)) {
    return s.replace(re, ' ').trimRight();
  }

  return s;
};

/*
 * WP can output things like &shy; but they can't be rendered as children because then they're just strings as are.
 * Basically this could be called 'getChildrenWithHtmlEntities' but I feel it's too long
 *
 * Usage: <MyTextComponent {...getTextChildren(textPropsWithChildren)} />
 */

export const getTextChildren = ({
  children,
  ...props
}: {
  children?: null | string | ReactNode;
}) => {
  if (typeof children === 'string') {
    // children sanitized from possible &nbsp;
    const safeChildren = sanitizeCmsStrings(children);
    // pattern to check for left out html entities
    const entitiesPattern = /&[a-z]+;/g;

    // if there's unsanitized patterns left, render as html. Such pattern can be &shy; eg.
    if (entitiesPattern.test(children)) {
      return {
        dangerouslySetInnerHTML: {
          __html: safeChildren,
        },
        children: null,
      };
    }
    return {
      ...props,
      children: safeChildren,
    };
  }
  return { children, ...props };
};

export const slugify = (oStr: string): string => {
  let str = oStr.trim().toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaaaeeeeiiiioooouuuunc------';

  for (let i = 0; i < from.length; i += 1) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  return str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes
    .replace(/^-+/, '') // trim - from start of text
    .replace(/-+$/, ''); // trim - from end of text
};

// Same as qs, but without 'undefined' as value. TODO: maybe get rid of this and replace with qs with some refactors
export const parseParams = (querystring: string) => {
  // take query string and make it go object
  const params = new URLSearchParams(querystring);
  const values = [...(params.values() as unknown as string[])];
  return [...(params.keys() as unknown as string[])].reduce<
    Record<string, string | string[]>
  >((acc, key, i) => {
    if (!acc[key]) {
      return { ...acc, [key]: values[i] };
    }
    const a = acc[key];
    return {
      ...acc,
      [key]: Array.isArray(a) ? [...a, values[i]] : [a, values[i]],
    };
  }, Object.create(null));
};

/**
 * Splits a text in two columns, trying to make sure
 * that the columns are more or less equal in length.
 *
 * @param text any lengthy text
 * @param options.delimiter the character to use as splitting point (normally space or newline)
 * @param options.trim should the parts be whitespace-trimmed before returning
 * @returns an array with two items, being the two columns of text
 */
export const columnize = (
  text?: string | null,
  options?: { delimiter?: string; trim?: boolean },
) => {
  if (!text) {
    return [];
  }
  const { delimiter = ' ', trim } = options || {};
  const middleIndex = text.indexOf(delimiter, Math.ceil(text.length / 2));
  return [text.slice(0, middleIndex), text.slice(middleIndex)].map((p) =>
    trim ? p.trim() : p,
  );
};

export const yesNoUndefined = (
  field: boolean | null | undefined,
  t: TFunction,
  notBooleanValue?: string | null,
) => {
  if (typeof field !== 'boolean') {
    // for example if we the field value is null or undefined,
    // then we might want to return null. Only if nonBooleanValue is undefined
    // we use the hard coded fallback '-'
    return typeof notBooleanValue !== undefined ? notBooleanValue : '-';
  }
  return field ? t('yes', 'Kyllä') : t('no', 'Ei');
};

export const yesOrNull = (field: boolean | null | undefined, t: TFunction) => {
  return field ? t('yes', 'Kyllä') : null;
};

export default { getTextChildren };
