import {
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from '@chakra-ui/react';
import Link from '../../Link';
import { NavPost } from '@/types';
import { NavList, NavListItem } from '../utils';

const MtSubMenuItem = ({
  url,
  title,
  children,
  isDeep,
  onNavigate,
}: NavPost & { isDeep?: boolean; onNavigate: () => void }) => (
  <NavListItem {...(isDeep ? null : { _notLast: { mb: 2 } })}>
    <Link
      href={url}
      fontSize="1rem"
      fontWeight="400"
      onClick={onNavigate}
      mb={children ? 4 : 0}
      display="block"
      _activeLink={{
        borderBottom: '2px solid var(--chakra-colors-highlightColor)',
        pointerEvents: 'none',
      }}
    >
      {title}
    </Link>
    {children && (
      <NavList>
        {children.map((item) => (
          <MtSubMenuItem {...item} key={item.ID} onNavigate={onNavigate} />
        ))}
      </NavList>
    )}
  </NavListItem>
);

const dropdownContentProps = {
  px: [4, 7],
  pt: [2, 4],
  pb: [4, 7],
  alignItems: 'flex-start',
  bg: 'white',
  width: 'auto',
  boxShadow: 'none',
  border: 0,
  borderRadius: 0,
  _focus: {
    boxShadow: 'none',
    outline: 'none',
  },
};

const DesktopMenuItem = ({
  title,
  children,
  onNavigate,
}: NavPost & { onNavigate: () => void }) => {
  return (
    <Popover placement="bottom-start" offset={[-30, 8]}>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Text as="button" type="button" fontSize="1rem" fontWeight="600">
              {title}
            </Text>
          </PopoverTrigger>
          <PopoverContent as={NavList} {...dropdownContentProps}>
            {children?.map((item) => (
              <MtSubMenuItem
                {...item}
                key={item.ID}
                onNavigate={() => {
                  onClose();
                  onNavigate();
                }}
              />
            ))}
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default DesktopMenuItem;
