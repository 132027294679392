import {
  FocusEventHandler,
  ReactNode,
  RefObject,
  MouseEventHandler,
  MouseEvent,
} from 'react';
import { useRouter } from 'next/router';
import {
  ChakraProps,
  Text,
  Link as ChakraLink,
  As,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { useConfig } from '@/config';

export interface LinkProps extends Omit<NextLinkProps, 'as' | 'href'> {
  href?: NextLinkProps['href'];
  shallow?: NextLinkProps['shallow'];
  ref?: RefObject<any>;
  disabled?: boolean;
  children?: ReactNode;
  className?: string;
  target?: ChakraLinkProps['target'];
  onClick?: MouseEventHandler;
  onMouseEnter?: MouseEventHandler<HTMLAnchorElement | HTMLParagraphElement>;
  onMouseOver?: MouseEventHandler<HTMLAnchorElement | HTMLParagraphElement>;
  onMouseOut?: MouseEventHandler<HTMLAnchorElement | HTMLParagraphElement>;
  onMouseMove?: MouseEventHandler<HTMLAnchorElement | HTMLParagraphElement>;
  onBlur?: FocusEventHandler<HTMLAnchorElement | HTMLParagraphElement>;
  onFocus?: FocusEventHandler<HTMLAnchorElement | HTMLParagraphElement>;
  title?: string;
  rel?: string;
  as?: As;
}

const styles = {
  _activeLink: { pointerEvents: 'none' },
};

// pretty loose way to check this, but it should do the trick. Asset urls take protocol relatively, so check for //
const isFullUrl = (s: string): boolean =>
  Boolean(
    s &&
      (/(http(s?)):\/\//i.test(s) ||
        s.indexOf('www.') === 0 ||
        s.indexOf('//') === 0),
  );

const isSelfUrl = (s: string, domain: string): boolean =>
  Boolean(s.includes(domain) || s.includes('localhost'));

// Not really needed, but maybe nice in development start
const isWpUrl = (s: string) =>
  !isFullUrl(s) && Boolean(s.includes('wordpress'));

const isActionHref = (s: string) =>
  s.startsWith('mailto:') || s.startsWith('tel:');

const Link = ({
  children,
  href: someHref,
  locale,
  disabled,
  prefetch = false /* NOTE: even with this, static pages are prefetched on hover */,
  onClick,
  target,
  shallow,
  id,
  ...props
}: LinkProps & Omit<ChakraProps, keyof LinkProps> & { id?: string }) => {
  const href = typeof someHref === 'string' ? someHref : '';
  const { asPath, locale: currentLocale } = useRouter();
  const { DOMAIN } = useConfig();

  if (!someHref) {
    return (
      <Text as="span" {...props} onClick={onClick} id={id}>
        {children}
      </Text>
    );
  }

  const nextLinkProps = {
    locale,
    prefetch,
    shallow,
    href: typeof someHref === 'object' ? someHref : href,
  };

  if (typeof someHref === 'object') {
    return (
      <NextLink {...nextLinkProps} passHref>
        <ChakraLink {...styles} {...props} onClick={onClick} id={id}>
          {children}
        </ChakraLink>
      </NextLink>
    );
  }

  const isExternalLink = isFullUrl(href);

  const autoProps: Record<string, unknown> = {};

  // if the href is an absolute url, then don't render the
  // wrapping next/link since it prepends the baseUrl when
  // used with multi-domain routing, instead just render
  // a normal anchor element
  if (isExternalLink || isWpUrl(href) || isActionHref(href)) {
    if (!isSelfUrl(href, DOMAIN) || isActionHref(href)) {
      autoProps.target = '_blank';
      autoProps.rel = 'noopener nofollow';
    }

    return (
      <ChakraLink
        href={href}
        {...styles}
        onClick={onClick}
        target={target}
        {...autoProps}
        {...props}
        id={id}
      >
        {children}
      </ChakraLink>
    );
  }

  if (!disabled) {
    // relative url, so internal link and not disabled
    const isAnchorLink = !isExternalLink && href.startsWith('#');

    if (isAnchorLink) {
      // anchor links
      nextLinkProps.href = `${asPath.split('#')[0]}${href}`;
      autoProps.onClick = (e: MouseEvent<HTMLButtonElement>) => {
        if (document.querySelector(href)) {
          e.preventDefault();
          document?.querySelector(href)?.scrollIntoView({ behavior: 'smooth' });
        }
        if (onClick) {
          onClick(e);
        }
      };
    } else {
      // Normal internal links

      const [base] = href.split('/').filter(Boolean);
      if (base !== 'api') {
        if (asPath === href && (!locale || locale === currentLocale)) {
          // Is current page. Doesn't consider query params for a reason
          autoProps['aria-current'] = 'page';
        }
      }
    }
  } else {
    // disabled link
    nextLinkProps.href = '#';
  }

  return (
    <NextLink {...nextLinkProps} passHref>
      <ChakraLink
        {...styles}
        onClick={onClick}
        {...autoProps}
        {...props}
        target={target}
        id={id}
      >
        {children}
      </ChakraLink>
    </NextLink>
  );
};

export default Link;
