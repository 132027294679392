import {
  ListItem,
  Button,
  List,
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverContentProps,
} from '@chakra-ui/react';
import { useRouter, NextRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { CaretDown, House } from '@phosphor-icons/react';
import { Post } from '@/types';
import Link from '../Link';
import { langFiTranslations } from '@/lib/utils/translations';
import { mobileNavBreakpointKey } from './utils';
import { useAppdata } from '@/lib/hooks/useApp';

const dropdownContentProps: PopoverContentProps = {
  px: [4, 7],
  pt: [2, 3],
  pb: [3, 5],
  textAlign: { base: 'left', [mobileNavBreakpointKey]: 'center' },
  alignItems: 'flex-start',
  bg: 'white',
  width: 'auto',
  boxShadow: { base: 'none', [mobileNavBreakpointKey]: 'sm' },
  border: 0,
  borderRadius: 0,
  _focus: {
    boxShadow: { base: 'none', [mobileNavBreakpointKey]: 'sm' },
    outline: 'none',
  },
  sx: {
    li: {
      width: '100%',
      mb: 2,
    },
    span: {
      color: 'secondaryColor',
      opacity: 0.8,
    },
  },
};

const resolveWpTranslations = (
  translations: null | Post['translations'],
  { locales, locale }: Pick<NextRouter, 'locales' | 'locale'>,
) => {
  const unTranslatedLocales = (locales || []).filter(
    (l) => ![...Object.keys(translations || {}), locale].includes(l),
  );

  const translationEntries = translations ? Object.entries(translations) : [];
  if (!translationEntries.length) {
    // No translations are at least yet defined in WP.
    return unTranslatedLocales.map((l) => ({
      lang: l,
      url: '/',
      isFallback: true,
    }));
  }

  return translationEntries.reduce<{ lang: string; url?: string }[]>(
    (acc, [lang, url], i, arr) => {
      // Dont support WP languages that are not supported by our app, even though this kind of would be how WP site would work by default
      if (!locales?.includes(lang)) {
        return acc;
      }

      const item = { lang, url };

      // If there's still items to be looped, keep on looping
      if (i < arr.length - 1) {
        return [...acc, item];
      }
      // not more items, return all loopend concat with untranslated locales
      return [
        ...acc,
        item,
        ...unTranslatedLocales.map((l) => ({
          lang: l,
          url: '/',
          isFallback: true,
        })),
      ];
    },
    [],
  );
};

// translations includes only the direct translations of this page in all other languages which have been translated in WP.
const LangSelector = ({
  onNavigate,
  disabled,
}: {
  onNavigate?: () => void;
  disabled?: boolean;
}) => {
  const {
    asPath,
    locale,
    locales,
    query: { entry },
  } = useRouter();
  const { t } = useTranslation('frontoffice');
  const { entryTranslations } = useAppdata();
  // split the full path (incl query) on the questionmark,
  // but since it's valid to have multiple questionmarks in
  // a url, and we only want to split on the first, we re-join
  // the rest of the split array back into the full query string
  const [path, ...query] = asPath.split('?');
  const queryString = query ? query.join('?') : undefined;

  if (!locale) {
    return null;
  }

  // Translate WP urls (entry) based on given translations and the rest of the urls are hardcoded to the same path and always exist
  const translations: { lang: string; url?: string; isFallback?: boolean }[] =
    entry
      ? resolveWpTranslations(entryTranslations, { locales, locale })
      : (locales || [])
          .filter((l) => l !== locale)
          .filter((l) => l !== 'ru') // remove russian for now...
          .map((l) => ({ lang: l, url: path }));

  return (
    <Popover placement="bottom-end" matchWidth>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button
              variant="transparent"
              fontWeight="400"
              fontSize="sm"
              rightIcon={
                <CaretDown color="var(--chakra-colors-highlightColor)" />
              }
              disabled={disabled}
            >
              {t(
                `lang_${locale}`,
                langFiTranslations[locale as keyof typeof langFiTranslations] ||
                  locale,
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent as={List} {...dropdownContentProps}>
            {translations.map(({ lang, url, isFallback }) => (
              <ListItem key={lang}>
                <Link
                  locale={lang}
                  href={[url, !isFallback && queryString]
                    .filter(Boolean)
                    .join('?')}
                  {...(lang === locale ? { 'aria-current': 'page' } : null)}
                  onClick={() => {
                    onClose();
                    if (onNavigate) {
                      onNavigate();
                    }
                  }}
                  display="flex"
                  alignItems="center"
                  sx={{ '> svg': { mr: 1 } }}
                  disabled={disabled}
                >
                  {isFallback && <House weight="bold" />}
                  {t(
                    `lang_${lang}`,
                    langFiTranslations[
                      lang as keyof typeof langFiTranslations
                    ] || lang,
                  )}
                </Link>
              </ListItem>
            ))}
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default LangSelector;
