export default {
  variants: {
    outline: {
      field: {
        bg: 'white',
        borderWidth: '1px',
      },
    },
  },
  defaultProps: {
    focusBorderColor: 'blue.500',
    bg: 'white',
  },
  baseStyle: {
    bg: 'white',
    background: 'white',
  },
};
