const parts = ['popper', 'content', 'header', 'body', 'footer', 'arrow'];
export default {
  parts,
  baseStyle: {
    popper: {
      width: 'fit-content',
      maxWidth: 'fit-content',
    },
    content: {
      boxShadow: 'xl',
      _focus: {
        boxShadow: 'xl',
      },
    },
  },
};
