// theme.js
import { extendTheme, CSSObject, Theme } from '@chakra-ui/react';
// Global style overrides
import styles from './styles';
// Foundational style overrides
import colors from './foundations/colors';
import layerStyles from './foundations/layerStyles';
import sizes from './foundations/sizes';
import { fontSizes, fonts } from './foundations/fonts';
import textStyles from './foundations/textStyles';
// Component style overrides
import Accordion from './components/accordion';
import Button from './components/button';
import Input from './components/input';
import FormLabel from './components/form-label';
import Textarea from './components/textarea';
import Checkbox from './components/checkbox';
import Popover from './components/popover';
import Heading from './components/heading';
import Tabs from './components/tabs';

const theme = extendTheme({
  styles,
  sizes,
  layerStyles,
  fontSizes,
  colors,
  fonts,
  textStyles,
  // Other foundational style overrides go here
  components: {
    Accordion,
    Textarea,
    Button,
    Input,
    Checkbox,
    Popover,
    FormLabel,
    Heading,
    Tabs,
  },
});

/*
 * NOTE: requires casting to Theme since 1.8.6 update at least
 * The types used to be better, but now it's just a Dict and there's NOTE about it in source code
 */
export const { breakpoints } = theme as Theme;

const createMaxBreakpoint = (bp: string) =>
  `${Number.parseInt(bp, 10) - 0.001}em`;

export const safariOnlyCss = (
  rules: CSSObject,
  breakpoint?: keyof typeof breakpoints,
  useMaxBreakpoint = false,
): CSSObject => ({
  '@media not all and (min-resolution:.001dpcm)': {
    '@supports (-webkit-appearance:none) and (display:flow-root)': breakpoint
      ? {
          [`@media (${useMaxBreakpoint ? 'max' : 'min'}-width: ${
            useMaxBreakpoint
              ? createMaxBreakpoint(breakpoints[breakpoint])
              : breakpoints[breakpoint]
          })`]: rules,
        }
      : rules,
  },
});

const sortedBreakpointEntries = Object.entries(breakpoints).sort(
  ([, a], [, b]) => (a < b ? -1 : 0),
) as Array<[keyof typeof breakpoints, string]>;

export const getNextBreakpoint = (
  bp: keyof typeof breakpoints,
): keyof typeof breakpoints => {
  const i = sortedBreakpointEntries.findIndex(([bpKey]) => bpKey === bp);
  return sortedBreakpointEntries[i + 1][0] || bp;
};

export default theme;
