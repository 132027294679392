import { Box } from '@chakra-ui/react';
import Nav from './Nav';
import { useAppdata } from '@/lib/hooks/useApp';
import { mobileNavBreakpointKey } from './utils';
import { resolveMainNavItems } from '@/lib/utils/navigations';

const Header = () => {
  const {
    navigations: { main_navigation: items },
  } = useAppdata();

  return (
    <Box
      as="header"
      bg="white"
      width="100%"
      borderTop="4px solid"
      borderTopColor="primary.500"
      zIndex="50"
      position={{ base: 'sticky', [mobileNavBreakpointKey]: 'static' }}
      top={0}
      layerStyle="noPrint"
    >
      <Nav
        items={resolveMainNavItems(items)}
        maxW="calc(var(--chakra-sizes-container-2xl) - 100px + 2 * var(--chakra-space-5))"
        width="100%"
        py={{ base: 3, [mobileNavBreakpointKey]: 5 }}
        px="5"
        m="0 auto"
      />
    </Box>
  );
};

export default Header;
