import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import {
  Box,
  Flex,
  useDisclosure,
  Collapse,
  Button,
  Accordion,
  ChakraProps,
  AccordionProps,
  UseAccordionProps,
  BoxProps,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { List as MenuIcon } from '@phosphor-icons/react';
import { NavPost } from '@/types';
import Logo from '@/components/common/Logo';
import {
  mobileNavBreakpointKey,
  navNextBreakpointKey,
  useIsMobileHeader,
  mobileNavMaxBreakpointValue,
  NavList as PlainList,
  NavListItem,
} from './utils';
import { safariOnlyCss } from '@/theme';
import LangSelector from './LangSelector';

// Import desktop item always, because it depends on isMobile, which only works on client side and hence causing odd loading on desktop. Alternative `is-mobile` -dep could be used.
import DesktopMenuItem from './MenuItems/DesktopItem';

const MobileMenuItem = dynamic(() => import('./MenuItems/MobileItem'));
const LinkItem = dynamic(() => import('./MenuItems/LinkItem'));

const NavListTopItem = (props: BoxProps) => (
  <NavListItem
    {...props}
    _notLast={{
      base: { marginBottom: 4 },
      [mobileNavBreakpointKey]: { marginBottom: 0 },
    }}
  />
);

const MtMenuItem = ({
  isMobile,
  defaultIndexes,
  anyExpanded,
  ...item
}: NavPost & {
  isMobile: boolean;
  onNavigate: () => void;
  defaultIndexes?: number[];
  anyExpanded?: boolean;
}) => {
  // menu items are always just links in case they dont have children
  if (!item.children) {
    return <LinkItem {...item} as={NavListTopItem} />;
  }

  if (!isMobile) {
    return (
      <NavListTopItem>
        <DesktopMenuItem {...item} />
      </NavListTopItem>
    );
  }

  return (
    <MobileMenuItem
      {...item}
      {...{ defaultIndexes, anyExpanded }}
      as={NavListTopItem}
    />
  );
};

/* NOTE: I couldn't figure out another way without compromising the animation/transition
 * Collapse takes only 'style' and that can't handle @media and probably not theme props either
 */
const MobileMenu = styled(Collapse)`
  flex: 1 1 auto;

  @media (max-width: ${mobileNavMaxBreakpointValue}) {
    position: fixed;
    top: 68px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 0 3rem;
    background: white;
    z-index: 1;
    overflow-y: auto !important;
  }
`;

const mobileAccordionProps: Record<string, unknown> &
  Pick<AccordionProps, 'as' | 'allowToggle' | 'allowMultiple'> = {
  allowToggle: true,
  allowMultiple: true,
  id: 'main-mobile-menu',
  as: 'ul',
  width: '100%',
};

// Resolve the default state of accordion based on its items and the current path of the website
const resolveDefaultAccordionIndexes = ({
  entry,
  children,
}: Pick<NavPost, 'children'> & { entry?: string[] }): number[] => {
  if (!entry || !children) {
    return [];
  }

  const path = `/${entry.join('/')}`;

  // Basically "go deep until you find the match if such exists, and report back with the indexes you went through to find the match"
  const findIndexesForPathMatch = (
    items: NavPost[],
    gatheredIndexes: number[] = [],
  ): false | number[] => {
    let i = 0; // Index for the while
    let index = -1; // Real index of accordion. Skips non-accordion items
    const isFinalLevel = !items?.some(({ children: c }) => c); // Final level will have no children and it is to be counted in indexes

    while (i < items.length) {
      const item = items[i];

      if (item.children || isFinalLevel) {
        index += 1;
      } else if (item.url === path) {
        // else skips all solo items from the index, which are not of type AccordionItem
        return false;
      }

      if (item.url === path) {
        return [...gatheredIndexes, index];
      }

      if (item.children?.length) {
        const indexes = findIndexesForPathMatch(item.children, [
          ...gatheredIndexes,
          index,
        ]);

        if (indexes && indexes?.length) {
          return indexes;
        }
      }

      i += 1;
    }

    return false;
  };
  return findIndexesForPathMatch(children) || [];
};

interface State {
  anyExpanded: boolean;
  defaultAccordionIndexes: number[];
}

const Nav = ({ items, ...props }: ChakraProps & { items: NavPost[] }) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const {
    query: { entry },
    locale,
  } = useRouter();

  /*
   * isMobile defaults to true on Server Side, while it is really undefined
   * This is by choice since there is no is-mobile -package in use.
   * It is better to optimize loading of packages for mobile rather than desktop.
   */
  const isMobile = useIsMobileHeader();

  const [
    {
      anyExpanded,
      defaultAccordionIndexes: [defaultAccordionIndex, ...defaultSubIndexes],
    },
    setState,
  ] = useState<State>(() => ({
    anyExpanded: false,
    defaultAccordionIndexes:
      isMobile && entry
        ? resolveDefaultAccordionIndexes({
            entry: typeof entry === 'string' ? [entry] : entry,
            children: items,
          })
        : [],
  }));

  useEffect(() => {
    if (isMobile) {
      // Default open indexes for mobile accordions
      if (entry) {
        setState((s) => ({
          ...s,
          defaultAccordionIndexes: resolveDefaultAccordionIndexes({
            entry: typeof entry === 'string' ? [entry] : entry,
            children: items,
          }),
        }));
      }
    }
  }, [isMobile, entry, items]);

  const onChange: UseAccordionProps['onChange'] = (
    openIndexes: number[],
  ): void => {
    // Used only in mobile
    if (anyExpanded && !openIndexes.length) {
      setState((s) => ({ ...s, anyExpanded: false }));
    } else if (!anyExpanded && openIndexes.length) {
      setState((s) => ({ ...s, anyExpanded: true }));
    }
  };
  const NavList = isMobile ? Accordion : PlainList;

  const isMobileMenuOpen = isOpen && isMobile;

  useEffect(() => {
    document?.body?.classList[isMobileMenuOpen ? 'add' : 'remove'](
      'mobile-menu-open',
    );

    return () => {
      document?.body?.classList.remove('mobile-menu-open');
    };
  }, [isMobileMenuOpen]);

  let accordionIndex = -1;

  return (
    <Flex
      as="nav"
      alignItems="center"
      justifyContent="space-between"
      {...props}
    >
      <Box flex="1 0 auto" mr={8}>
        <Logo paddingTop="5px" paddingBottom="5px" locale={locale} />
      </Box>
      <MobileMenu in={isOpen || !isMobile} animateOpacity>
        <NavList
          display={{
            base: isOpen ? 'flex' : 'none',
            [mobileNavBreakpointKey]: 'flex',
          }}
          flexDirection={{
            base: 'column',
            [mobileNavBreakpointKey]: 'row',
          }}
          justifyContent={{
            [mobileNavBreakpointKey]: 'flex-start',
          }}
          gridGap={{
            [mobileNavBreakpointKey]: 4,
            [navNextBreakpointKey]: 8,
          }}
          alignItems={{ [mobileNavBreakpointKey]: 'center' }}
          _first={{ [mobileNavBreakpointKey]: { marginLeft: 'auto' } }}
          sx={safariOnlyCss(
            { '> *:not(:last-child):not(:first-child)': { ml: 4 } },
            mobileNavBreakpointKey,
          )}
          {...(typeof defaultAccordionIndex === 'number' && isMobile
            ? { defaultIndex: [defaultAccordionIndex] }
            : null)}
          {...(isMobile
            ? { ...mobileAccordionProps, onChange: onChange as any }
            : null)}
        >
          {items?.map((item) => {
            if (item.children) {
              accordionIndex += 1;
            }

            return (
              <MtMenuItem
                {...item}
                key={item.ID}
                isMobile={isMobile}
                onNavigate={onClose}
                defaultIndexes={
                  typeof defaultAccordionIndex === 'number' &&
                  accordionIndex === defaultAccordionIndex
                    ? defaultSubIndexes
                    : undefined
                }
                anyExpanded={anyExpanded}
              />
            );
          })}
          <NavListTopItem m={{ [mobileNavBreakpointKey]: 'auto 0 auto auto' }}>
            <LangSelector onNavigate={onClose} />
          </NavListTopItem>
        </NavList>
      </MobileMenu>
      <Button
        display={{ base: 'inline-flex', [mobileNavBreakpointKey]: 'none' }}
        onClick={onToggle}
        variant="transparent"
        pos="relative"
        px="4"
        py="2"
        mr="-4"
      >
        {isOpen ? <MenuIcon size="24" /> : <MenuIcon size="24" />}
      </Button>
    </Flex>
  );
};
export default Nav;
