import { List, ListItem } from '@chakra-ui/react';
import { SiteNav } from '@/types';
import Link from './Link';
import { renderIcon } from './SocialShare';

const SocialMediaLinkList = ({
  socialMediaLinks,
}: {
  socialMediaLinks: SiteNav['social_media_links'];
}) =>
  socialMediaLinks?.length ? (
    <List
      display="flex"
      gridGap={4}
      justifyContent={['center', null, 'flex-end']}
      flex={['0 0 100%', null, '0 0 auto']}
    >
      {socialMediaLinks?.map((service) => (
        <ListItem key={service.title}>
          <Link href={service.url}>{renderIcon(service.title)}</Link>
        </ListItem>
      ))}
    </List>
  ) : null;

export default SocialMediaLinkList;
